import React from "react"
import useTranslations from '../../components/useTranslations';
import * as S from '../../components/Solutions/styled'
import YES from '../../images/icons/yes.svg'
import NO from '../../images/icons/no.svg'
import OPS from '../../images/icons/opsiyonel.svg'
import BMS from '../../images/icons/BMS.svg'
import EMS from '../../images/icons/EMS.svg'
import PMS from '../../images/icons/PMS.svg'
import DCIM from '../../images/icons/DCIM.svg'

const SolutionsTable = () => {
  const {
    modules
  } = useTranslations();

  return (
    <S.RowStart>
      <S.Column>
        <S.Title2>{modules.modulesTitle}</S.Title2>
        <S.SubTitle>{modules.modulesDesc}</S.SubTitle>
        <br />
        <S.BorderedColumn>
          <S.CompareTable>
            <thead>
              <tr>
                <S.CompareTableTH></S.CompareTableTH>
                <S.CompareTableTH>
                  <S.TableImage w="12rem" src={EMS} />
                </S.CompareTableTH>
                <S.CompareTableTH>
                  <S.TableImage w="12rem" src={BMS} />
                </S.CompareTableTH>
                <S.CompareTableTH>
                  <S.TableImage w="12rem" src={PMS} />
                </S.CompareTableTH>
                <S.CompareTableTH>
                  <S.TableImage w="12rem" src={DCIM} />
                </S.CompareTableTH>
              </tr>
            </thead>
            <tbody>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {/* <S.SvgFaExclamationCircle />{' '}
                      <S.SubItem> asdasdasdasd</S.SubItem> */}
                  {modules.multi}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.heatMap}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.heatMapT}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.humid}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.cam}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.location}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.ruleEditor}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>Alias Editor</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.visual}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>PUE </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.graphMon}</S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Trend </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Histogram</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Scatter Plot</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.alarmMan}</S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Basic Alarms</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Advanced Alarms</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.dataCollect}</S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
                <S.CompareTableTD></S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTRSub>
                <S.CompareTableTD>SNMP</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Modbus RTU</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>Modbus TCP/IP</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>IP Monitor</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRSub>
                <S.CompareTableTD>OPC UA</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTRSub>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.historicalData}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>
                  {modules.reportMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.webApp}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.userMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>
                  {modules.ruleMan}
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.assetMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.changeMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.energyMan} </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.consMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={YES} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.mainMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTR>
              <S.CompareTableTRGray>
                <S.CompareTableTD>{modules.taskMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTRGray>
              <S.CompareTableTR>
                <S.CompareTableTD>{modules.cableMan}</S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage w="1rem" src={NO} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
                <S.CompareTableTD>
                  <S.TableImage src={OPS} />
                </S.CompareTableTD>
              </S.CompareTableTR>
            </tbody>
          </S.CompareTable>
          <div className='table-legion'>
            <div className='table-legion-label'>
              <S.TableImage src={YES} />  : {modules.available}
            </div>
            <div className='table-legion-label'>
              <S.TableImage w="1rem" src={NO} /> : {modules.unavailable}
            </div>
            <div className='table-legion-label'>
              <S.TableImage src={OPS} /> : {modules.optional}
            </div>
          </div>
        </S.BorderedColumn>
      </S.Column>
    </S.RowStart>
  )
};

export default SolutionsTable;
