import React from 'react'
import { graphql } from 'gatsby'
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/seo'
import * as L from '../components/Solution/styled'
import * as S from '../components/Content/styled';
import SolutionsTable from "../components/Solution/SolutionsTable"
import useTranslations from '../components/useTranslations'
import DocumentDownload from "../components/LandingPage/documentDownload"
import Feedback from "../components/LandingPage/feedback"

const Solution = props => {
  const post = props.data.markdownRemark

  const image = post.frontmatter.featuredImage ? post.frontmatter.featuredImage.childImageSharp.fluid : null
  const imageSRC = post.frontmatter.featuredImage ? post.frontmatter.featuredImage.childImageSharp.fluid.src : null
  const { retmonTitle, landingPage, request } = useTranslations()

  return (
    <>

      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={post.frontmatter.description}
        image={post.frontmatter.imageURL ? post.frontmatter.imageURL : 'https://www.retmon.com' + imageSRC}
      />
      <L.Title>{post.frontmatter.title}</L.Title>  <L.SubTitle>{post.frontmatter.subTitle}</L.SubTitle>
      {post.frontmatter.featuredImage ?
        <L.Row
          justify='space-between'
          wrap>
          <L.Column
            text='-webkit-center'
            auto>
            <L.GatsbyImage fluid={image} alt={post.frontmatter.description} />
            <L.SolutionLogo fixed={post.frontmatter.logo.childImageSharp.fixed} alt={post.frontmatter.description} />
          </L.Column>
        </L.Row>
        :
        null
      }
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.Content>
      <Feedback />
      <SolutionsTable />
    </>
  )
}

export const query = graphql`
  query Solution($locale: String!, $title: String!) {
        markdownRemark(
      frontmatter: {title: {eq: $title } }
      fields: {locale: {eq: $locale } }
    ) {
        frontmatter {
        title
        imageURL
        description
        subTitle
        logo {
          childImageSharp {
            fixed(quality:100)  {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
    }
    html
  }
}
`

export default Solution
